import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

import { BannerMonthlyPromo } from '../../views/offer/banner-monthly-promo';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

const Offer1 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "ig-zmarszczki-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      two: file(relativePath: { eq: "ig-zmarszczki-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      three: file(relativePath: { eq: "ig-zmarszczki-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      four: file(relativePath: { eq: "ig-zmarszczki-4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      fifth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      five: file(relativePath: { eq: "gs-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      six: file(relativePath: { eq: "gs-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      seven: file(relativePath: { eq: "posty_ideale_8.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      eight: file(relativePath: { eq: "posty_ideale_10.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.usuwanieZmarszczek}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Usuwanie zmarszczek mimicznych
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fifth.childImageSharp}
                alt="usuwanie zmarszczek botox toksyna botulinowa ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Wskazania</Subtitle>
            <List>
              <ListItem>
                Redukcja zmarszczek mimicznych: lwia zmarszczka, zmarszczki
                poprzeczne czoła, kurze łapki
              </ListItem>
              <ListItem>Redukcja zmarszczek “króliczych” na nosie</ListItem>
            </List>
          </Section>
          <BannerMonthlyPromo />
          <Subtitle>Opis zabiegu</Subtitle>
          <Paragraph>
            Zabieg podania preparatu{' '}
            <a href="/leksykon/jak-pozbyc-sie-zmarszczek/" target="_blank">
              usuwającego zmarszczki
            </a>{' '}
            zawsze poprzedzony jest indywidualną konsultacją lekarza medycyny
            estetycznej. Polega on na podaniu w kilka punktów bardzo cienką igłą
            preparatu zawierającego substancję, która hamuje uwalnianie
            acetylocholiny, neuroprzekaźnika odpowiadającego za przekaźnictwo
            nerwowo-mięśniowe. W efekcie mięsień nie kurczy się, a zmarszczka
            się rozprostowuje. Usuwanie{' '}
            <a
              href="/leksykon/jak-pozbyc-sie-zmarszczek-na-czole/"
              target="_blank"
            >
              zmarszczek na czole
            </a>{' '}
            podczas tego zabiegu daje naturalny i całkowicie odwracalny efekt.
            To jedyny skuteczny sposób na zmarszczki mimiczne w górnych partiach
            twarzy. Jest to świetna profilaktyka pierwszych zmarszczek dla osób
            o silnych mięśniach mimicznych, u których nie utworzyły się jeszcze
            głębokie bruzdy. Wielu pacjentów porównuje ból do kilkusekundowego
            ugryzienia przez komara. Zabieg nie wymaga znieczulenia. W przypadku{' '}
            <a href="/leksykon/bruksizm/" target="_blank">
              leczenia bruksizmu
            </a>{' '}
            zabieg wygląda podobnie.
          </Paragraph>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty</Subtitle>
            <Paragraph>
              Efekty redukcji zmarszczek mimicznych pojawią się już po kilku
              dniach (pełne efekty po 14 dniach) i utrzymują się 3-6 miesięcy.
              Zabieg najlepiej powtórzyć po ok. 6 miesiącach co pozwoli na
              uzyskanie długotrwałego efektu. Zdjęcia ukazują efekt zabiegu u
              konkretnego pacjenta. Efekt zabiegu może się różnić w zależności
              od indywidualnych cech pacjenta, liczby powtórzeń zabiegu,
              stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności
              i doświadczenia osoby przeprowadzającej zabieg.
            </Paragraph>
            <Subtitle>Zdjęcia przed po redukcji zmarszczek mimcznych</Subtitle>
            <SImageWrapper>
              <Image
                src={images.one.childImageSharp}
                alt="usuwanie zmarszczek zmarszczki poprzeczne czoła botox toksyna botulinowa ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.two.childImageSharp}
                alt="usuwanie zmarszczek zmarszczki poprzeczne czoła botox toksyna botulinowa ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.three.childImageSharp}
                alt="usuwanie zmarszczek lwia zmarszczka botox toksyna botulinowa ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.four.childImageSharp}
                alt="usuwanie zmarszczek kurze łapki botox toksyna botulinowa ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena</Subtitle>
            <Paragraph>
              <b>Cena zabiegu</b> zaczyna się od <b>400 zł</b>. Przy pierwszej
              wizycie odbierz swoją kartę lojalnościową i otrzymaj{' '}
              <b>-30% na czwarty zabieg medycyny estetycznej</b> wykonany w
              Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>
                Choroby nerwowo-mięśniowe t.j. miastenia gravis, Zespół
                Lamberta-Eatona
              </ListItem>
              <ListItem>
                Stosowanie bądź odstawienie w przeciągu tygodnia poprzedzającego
                zabieg leków takich, jak: D-penicylamina, cyklosporyna,
                tubokuraryna, pankuronium, galamina, sukcynylocholina,
                linkomycyna, tetracyklina, polimyksyna, aminochinolony,
                aminoglikozydy
              </ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Czym jest lwia zmarszczka?</Subtitle>
            <Paragraph>
              Lwia zmarszczka to pojedyncza lub podwójna linia lub bruzda, która
              jest widoczna gołym okiem w okolicy twarzy, pomiędzy naszymi
              brwiami. Jest ona często przyczyną pierwszej wizyty w gabinecie
              medycyny estetycznej . Pojawia się zwykle u osób o bogatej mimice
              twarzy. Nadaje twarzy groźnego wyglądu. Redukcja lub całkowite
              usunięcie lwiej zmarszczki to bardzo prosty i bezpieczny zabieg
              dający efekty na 3-6 miesięcy. Po tym czasie zabieg należy
              powtórzyć aby zabieg pogłębianiu się zmarszczki na skutek dalszej
              aktywnej mimiki.
            </Paragraph>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              <b>
                IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę
                dla kobiet.
              </b>{' '}
              Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w
              sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące.
              Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny
              estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi,
              doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas
              piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na
              młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie
              na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd
              naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc
              parkingowych.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych
              zabiegów medycyny estetycznej, które czerpiemy prosto z
              międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość.
              Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu
              doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą
              skórą.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer1;

export const Head = () => <SEO tags={TAGS.usuwanieZmarszczek} />;
